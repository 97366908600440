<template>
  <div>
    <Nav />
    <router-view />
    <Footer />
  </div>
</template>
<script>
  import Nav from './nav.vue'
  import Footer from './footer.vue'
  export default {
    components: {
      Nav,
      Footer,
    },
  }
</script>
