import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/home.vue'),
      },
      {
        path: 'brand',
        name: 'brand',
        component: () => import('@/views/brand.vue'),
      },
      {
        path: 'pro',
        name: 'pro',
        component: () => import('@/views/pro.vue'),
      },
      {
        path: 'join',
        name: 'join',
        component: () => import('@/views/join.vue'),
      },
      {
        path: 'case',
        name: 'case',
        component: () => import('@/views/case.vue'),
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/contact.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
