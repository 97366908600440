<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  export default {
    data() {
      return {
        hash: null,
      }
    },
    mounted() {
      setTimeout(() => {
        this.$watch(
          '$route',
          (val) => {
            if (val.hash) {
              const si = setInterval(() => {
                if (document.readyState == 'complete') {
                  const el = document.querySelector(val.hash)
                  if (el) {
                    el?.scrollIntoView(true)
                    clearInterval(si)
                  }
                }
              }, 100)
            }
          },
          {
            immediate: true,
          }
        )
      }, 10)
    },
  }
</script>
<style>
  html,
  body {
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 16px;
  }
  #app {
    position: relative;
    max-width: 1980px;
    margin: 0 auto;
  }
  .demo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    opacity: 0.3;
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mt-60 {
    margin-top: 60px;
  }
</style>
